import {Injectable} from '@angular/core';
import {CanActivate, CanActivateChild, Router} from '@angular/router';
import {ConfigService} from "./_services/config.service";

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanActivateChild {
    constructor(public cfg: ConfigService, public router: Router) {
    }

    async canActivate(): Promise<boolean> {
        const authName = await this.cfg.get('authName')

        let auth: any;
        try {
            const fake = JSON.parse(sessionStorage.getItem('fake') || 'false')
            if (fake)
                auth = JSON.parse(sessionStorage.getItem(authName) || '');// фейковый токен храним в сессии
            else
                auth = JSON.parse(localStorage.getItem(authName) || '');
        } catch (e) {
            auth = {}
        }

        if (auth && !auth.token) {
            this.router.navigateByUrl('/auth/login');
            return false;
        }
        return true;
    }

    async canActivateChild(): Promise<boolean> {
        return await this.canActivate();
    }
}

import {Injectable} from '@angular/core';
import {ErrorService} from "./error.service";

export interface Config {
    apiUrl: string;
    DEBUG: boolean;
    authName: string;
    timeout: number;
    retry: number;
    pageLimit: number
    statisticApiUrl: string
}

type stringKeys = 'apiUrl' | 'authName' | 'statisticApiUrl'


@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    private configUrl = 'assets/config.json';
    private config!: Config;

    constructor(private error: ErrorService) {}

    async isDEBUG():Promise<boolean> {
        await this.load()
        return this.config['DEBUG']
    }

    async getPageLimit():Promise<number> {
        await this.load()
        return this.config['pageLimit']
    }

    async get(key: stringKeys): Promise<string> {
        await this.load()
        return this.config[key];
    }

    async load() {
        if (!this.config) {
            for (let i=0; i<5; i++) {
                try {
                    const response = await fetch(this.configUrl)
                    if (response.ok) {
                        this.config = await response.json()
                        return true
                    }
                } catch (e) {
                    console.log(e)
                }
            }
            return false
        }
        return true
    }
}


